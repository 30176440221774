<template>
  <div class="select-group flex align-center fs-14">
    <div class="select-item bold">{{title}}</div>
    <div
      @click="$emit('input', item.id)"
      v-for="item in data"
      :key="item.id"
      class="select-item pointer"
      :class="{active: value == item.id}"
    >{{item.name}}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: ''
    },
    title: {
      type: String
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.select-item {
  padding: 7px 19px;
  border-radius: 1000px;
  user-select: none;
  min-width: 80px;
  text-align: center;

  &.active {
    background: #00999F;
    color: white;
  }
}
</style>
