<template>
  <div v-if="item" class="material-item fs-14 bg-green white" :style="{background: item.background}">
    <!-- 头部 -->
    <div class="material-item-header flex justify-around align-center border-bottom">
      <div class="flex-1 header-left-info flex flex-column justify-center align-center">
        <img class="material-icon" :src="item.icon">
        <span class="mt-5" style="padding: 0 5px;">{{item.machineID}}-{{item.stockCode}}</span>
      </div>
      <div class="flex-1 flex flex-column justify-center align-center h-100-p">
        <p class="lh-26">{{item.norms}}</p>
        <span class="mt-5">{{item.breed}}</span>
      </div>
    </div>

    <!-- 数据信息部分 -->
    <div class="material-info-box relative">
      <div class="hr bg-white"></div>
      <div class="flex border-bottom">
        <!-- 当前库存 -->
        <div class="cell-item flex-1 flex justify-center align-center" title="当前库存">{{item.stockNum}}</div>
        <!-- 测量库存 -->
        <div class="cell-item flex-1 flex justify-center align-center" title="测量库存">-</div>
      </div>

      <div class="flex border-bottom">
        <div class="flex-1 flex flex-column justify-center align-center">
          <!-- 入库数量 -->
          <div class="cell-item border-bottom w-100-p flex justify-center" title="入库数量">{{item.inputNum}}</div>
          <!-- 消耗数量 -->
          <div class="cell-item" title="消耗数量">{{item.outputNum}}</div>
        </div>
        <!-- 当前库存百分比 -->
        <div class="flex-1 flex justify-center align-center cell-item" title="当前库存百分比">{{item.percent + item.percent && '%' || '-'}}</div>
      </div>
    </div>

    <!-- 底部操作部分 -->
    <div class="action-group flex justify-around align-center">
      <!-- <i class="fs-24 pointer iconfont icon-shipin1"></i> -->
      <i class="fs-24 pointer iconfont icon-caozuo-kaiguan" :class="{cssfilter:item.onOff=='0'}" @click="handleSwitch"></i>
      <i class="fs-24 pointer el-icon-s-tools" @click="handleSetting"></i>

      <!-- <i class="fs-24 pointer iconfont icon-baojing"></i> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  methods: {
    handleSwitch () {
      const index = this.index
      this.$emit('switch-change', index)
    },
    handleSetting () {
      const index = this.index
      this.$emit('handle-setting', index)
    }
  }
}
</script>

<style scoped lang="scss">
.cssfilter {
  filter: grayscale(1);
}
.material-item {
  width: 200px;
  border-radius: 10px;
  background: #184aa5;
  .material-item-header {
    height: 67px;
  }
  .header-left-info {
    min-width: 60px;
    height: 100%;
  }
  .material-icon {
    opacity: .9;
  }
  .material-info-box {
    overflow: hidden;
  }
  // 垂直分割线
  .hr {
    height: 100%;
    width: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .action-group {
    color: #46FDFF;
    opacity: .9;
    height: 42px;
  }
  .cell-item {
    line-height: 36px;
  }
  .border-bottom {
    border-bottom: 1px solid rgba(255,255,255,.8);
  }
  .white {
    color: rgba(255,255,255,.8);
  }
  .lh-26 {
    line-height: 26px;
  }
}
</style>
