<template>
  <div class="p-20" >
    <!-- 筛选条件 -->
    <div class="bg-white p-10" v-loading="loading">
      <SelectButton @input="onSearch" v-model="searchForm.machineID" title="生产线" :data="[
        {id: '', name: '全部'},
        {id: '1', name: '1号线'},
        {id: '2', name: '2号线'},
        {id: '3', name: '3号线'},
      ]"></SelectButton>
      <SelectButton @input="onSearch" class="mt-10" v-model="searchForm.stockType" title="材料" :data="[
        {id: '', name: '全部'},
        {id: '0', name: '水泥'},
        {id: '1', name: '粉煤灰'},
        {id: '2', name: '矿粉'},
        {id: '3', name: '膨胀剂'},
        {id: '4', name: '外加剂'},
        {id: '5', name: '砂'},
        {id: '6', name: '石'},
      ]"></SelectButton>
    </div>
    <div class="ml-15 fs-14 mt-15">
      （单位：吨）
    </div>

    <!-- 列表 -->
    <div class="material-list flex flex-wrap justify-around">
      <MaterialItem
        @switch-change="handleSwitchChange"
        @handle-setting="handleSetting"
        v-for="(item,index) in tableData"
        :key="index"
        :item="item"
        :index="index"
        class="mr-20 mb-20"
      ></MaterialItem>
      <i class="mr-20" v-for="item in 9" :key="'placeholder' + item" style="width: 200px;"></i>
    </div>
    <el-empty v-if="tableData.length==0" :image-size="200"></el-empty>

    <!-- 设置弹窗 -->
    <MaterialSetting :visible.sync="settingVisible" :edit-data.sync="editData" @refresh="initList"></MaterialSetting>

    <!-- 开关阀门 -->
    <switch-valve :visible.sync="switchVisible" :edit-data.sync="editData" @refresh="initList"></switch-valve>
  </div>
</template>

<script>
import { getMaterialViewPages } from '@/apis/stock/materialView'
import SelectButton from './SelectButton'
import MaterialItem from './MaterialItem'
import MaterialSetting from './MaterialSetting'
import SwitchValve from './SwitchValve'
import { roundNumber } from '@/utils/tools'
export default {
  components: {
    SelectButton,
    MaterialItem,
    MaterialSetting,
    SwitchValve
  },
  data () {
    return {
      loading: false,
      searchForm: {
        machineID: '',
        stockType: ''
      },
      icons: {
        0: require('@/assets/imgs/stock/shuini.png'), // 水泥 绿罐
        1: require('@/assets/imgs/stock/fenmeihui.png'), // 粉煤灰 蓝罐
        2: require('@/assets/imgs/stock/kuangfen.png'), // 矿粉 灰罐
        3: require('@/assets/imgs/stock/pengzhangji.png'), // 膨胀剂 紫罐子
        4: require('@/assets/imgs/stock/waijiaji.png'), // 外加剂 黄罐
        5: require('@/assets/imgs/stock/sha.png'), // 砂 黄土堆
        6: require('@/assets/imgs/stock/shi.png') // 石 灰土堆
      },
      backgrounds: {
        0: '#1A4CA8',
        1: '#28978e',
        2: '#28978e',
        3: '#28978e',
        4: '#28978e',
        5: '#228b25',
        6: '#228b25'
      },
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 9999,
        totalSize: 0
      },
      switchVisible: false,
      settingVisible: false,
      editData: null
    }
  },
  created () {
    this.initList()
  },
  methods: {
    handleSizeChange (value) {
      this.tablePage.pageSize = value
      this.initList()
    },
    handleCurrentChange (value) {
      this.tablePage.pageNum = value
      this.initList()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    async initList () {
      this.loading = true
      const data = {
        machineID: this.searchForm.machineID || undefined,
        stockType: this.searchForm.stockType || undefined,
        pageNum: this.tablePage.pageNum,
        pageSize: this.tablePage.pageSize
      }
      const res = await getMaterialViewPages(data)
      this.loading = false
      if (res.code == 200) {
        this.tableData = (res.data?.rows || []).map(item => {
          item.icon = this.icons[item.stockType]
          item.background = this.backgrounds[item.stockType]
          item.stockNumFormat = roundNumber(item.stockNum / 1000)
          return item
        })
        this.tablePage.totalSize = res.data.totalSize
      } else {
        this.$notify.error({
          title: '失败',
          message: res.msg
        })
      }
    },

    handleSwitchChange (index) {
      this.switchVisible = true
      this.editData = JSON.parse(JSON.stringify(this.tableData[index]))
    },
    handleSetting (index) {
      this.settingVisible = true
      console.log(777, this.tableData[index])
      this.editData = JSON.parse(JSON.stringify(this.tableData[index]))
    }
  }
}
</script>

<style scoped lang="scss">
.material-list {
  padding-top: 15px;
  // max-width: 1120px;
  margin: 0 auto;
}
.select-item {
  padding: 7px 19px;
  border-radius: 1000px;
  user-select: none;
  &.active {
    background: #00999F;
    color: white;
  }
}
</style>
