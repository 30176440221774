<template>
  <el-dialog
    v-bind="{title: '阀门开关', width:'320px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="50px" :model="submitForm" v-loading="loading">
      <el-form-item>
        <el-switch v-model="submitForm.onOff" :active-value="1" :inactive-value="0" class="mr-10" @change="submit"></el-switch>
        <span v-if="submitForm.onOff == 1" class="bold">阀门已开启</span>
        <span v-else class="bold">阀门已关闭</span>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { operateSwitch } from '@/apis/stock/materialView'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      loading: false,
      submitForm: {
        serialID: '', // 序号
        onOff: '' // 料口状态
      }
    }
  },
  methods: {
    submit () {
      const submitForm = { ...this.submitForm }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          operateSwitch(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('refresh')
            } else {
              submitForm.onOff = submitForm.onOff == '0' ? '1' : '0'
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerOpen () {
      if (this.editData) {
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          if (value != undefined) this.submitForm[key] = value
        })
      }
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    }
  }
}
</script>
