var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-20"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"bg-white p-10"},[_c('SelectButton',{attrs:{"title":"生产线","data":[
      {id: '', name: '全部'},
      {id: '1', name: '1号线'},
      {id: '2', name: '2号线'},
      {id: '3', name: '3号线'} ]},on:{"input":_vm.onSearch},model:{value:(_vm.searchForm.machineID),callback:function ($$v) {_vm.$set(_vm.searchForm, "machineID", $$v)},expression:"searchForm.machineID"}}),_c('SelectButton',{staticClass:"mt-10",attrs:{"title":"材料","data":[
      {id: '', name: '全部'},
      {id: '0', name: '水泥'},
      {id: '1', name: '粉煤灰'},
      {id: '2', name: '矿粉'},
      {id: '3', name: '膨胀剂'},
      {id: '4', name: '外加剂'},
      {id: '5', name: '砂'},
      {id: '6', name: '石'} ]},on:{"input":_vm.onSearch},model:{value:(_vm.searchForm.stockType),callback:function ($$v) {_vm.$set(_vm.searchForm, "stockType", $$v)},expression:"searchForm.stockType"}})],1),_c('div',{staticClass:"ml-15 fs-14 mt-15"},[_vm._v(" （单位：吨） ")]),_c('div',{staticClass:"material-list flex flex-wrap justify-around"},[_vm._l((_vm.tableData),function(item,index){return _c('MaterialItem',{key:index,staticClass:"mr-20 mb-20",attrs:{"item":item,"index":index},on:{"switch-change":_vm.handleSwitchChange,"handle-setting":_vm.handleSetting}})}),_vm._l((9),function(item){return _c('i',{key:'placeholder' + item,staticClass:"mr-20",staticStyle:{"width":"200px"}})})],2),(_vm.tableData.length==0)?_c('el-empty',{attrs:{"image-size":200}}):_vm._e(),_c('MaterialSetting',{attrs:{"visible":_vm.settingVisible,"edit-data":_vm.editData},on:{"update:visible":function($event){_vm.settingVisible=$event},"update:editData":function($event){_vm.editData=$event},"update:edit-data":function($event){_vm.editData=$event},"refresh":_vm.initList}}),_c('switch-valve',{attrs:{"visible":_vm.switchVisible,"edit-data":_vm.editData},on:{"update:visible":function($event){_vm.switchVisible=$event},"update:editData":function($event){_vm.editData=$event},"update:edit-data":function($event){_vm.editData=$event},"refresh":_vm.initList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }