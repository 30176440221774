<template>
  <el-dialog
    v-bind="{title: '设置', width: '1010px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="150px" :model="submitForm" :rules="rules">
      <FormModule title="筒仓基本信息">
        <el-row>
          <el-col :span="6">
            <el-form-item label="所属生产线">
              <el-input v-model="submitForm.machineID"></el-input>
            </el-form-item></el-col>
          <el-col :span="6">
            <el-form-item label="所属仓位">
              <el-input v-model="submitForm.stockCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="最大库存量（吨）">
              <el-input v-model="submitForm.stockNum"></el-input>
            </el-form-item></el-col>
          <!--          <el-col :span="6">
            <el-form-item label="最小预警值">
              <el-input></el-input>
            </el-form-item></el-col>-->
        </el-row>
      </FormModule>
      <FormModule title="物料基本信息">
        <el-row>
          <el-col :span="6">
            <el-form-item label="品种量">
              <el-input v-model="submitForm.breed"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格">
              <el-input v-model="submitForm.norms"></el-input>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="12">
            <el-form-item label="生产厂家">
              <el-input></el-input>
            </el-form-item>
          </el-col>-->
        </el-row>
      </FormModule>
      <FormModule title="出入库信息" class="mb-10">
        <div class="flex">
          <div class="w-100-p">
            <el-alert type="info" title="今日入库明细" :closable="false"/>
            <div class="detail-list mt-10 flex"
                 v-for="(item,index) in inOutVos"
                 :key="index"
                 :item="item"
                 :index="index">
              <DetailItem class="justify-around" :inOutOne="item"></DetailItem>
              <!--              <DetailItem class="mr-10 mb-10"  ></DetailItem>-->
              <!--              <DetailItem class="mr-10 mb-10"></DetailItem>
              <DetailItem class="mr-10 mb-10"></DetailItem>
              <DetailItem class="mb-10"></DetailItem>
              <DetailItem class="mr-10 mb-10"></DetailItem>
              <DetailItem class="mr-10 mb-10"></DetailItem>
              <DetailItem class="mr-10 mb-10"></DetailItem>
              <DetailItem class="mb-10"></DetailItem>-->
            </div>
          </div>
          <!--          <div class="flex-1">
            <el-alert type="warning" title="今日消耗" :closable="false"/>
            <DetailItem class="mt-10" type="2"></DetailItem>
            <div class="mb-10"></div>
            <el-alert type="warning" title="今日消耗" :closable="false"/>
            <DetailItem class="mt-10"></DetailItem>
          </div>-->
        </div>
      </FormModule>
      <!--      <FormModule title="库存操作">
        <el-row>
          <el-col :span="6">
            <el-form-item label="品种量">
              <el-select>
                <el-option></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="品种量">
              <el-select>
                <el-option></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格">
              <el-select>
                <el-option></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="品种量">
              <el-select>
                <el-option></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格">
              <el-select>
                <el-option></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="品种量">
              <el-select>
                <el-option></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="ml-20 w-100-p mb-20 flex justify-center">
              <el-button>盘存保存</el-button>
              <el-button>盘存置零</el-button>

            </div>
            <div class="ml-20 w-100-p flex justify-center">
              <el-button>盘存保存</el-button>
              <el-button>盘存置零</el-button>
            </div>
          </el-col>
        </el-row>
      </FormModule>-->
      <!--      <FormModule title="排队信息">
        <el-row>
        </el-row>
      </FormModule>-->
      <!--
      <el-form-item label="字典编码" prop="dictCode">
        <el-input class="w-200" v-model.trim="submitForm.dictCode" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="字典存储值" prop="dictValue">
        <el-input class="w-200" v-model.trim="submitForm.dictValue" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="字典存储值名称" prop="dictName">
        <el-input class="w-200" v-model.trim="submitForm.dictName" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="父级值" prop="dictParentValue">
        <el-input class="w-200" v-model.trim="submitForm.dictParentValue" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="层级" prop="dictLevel">
        <el-input class="w-200" v-model.trim="submitForm.dictLevel" clearable placeholder="请输入"></el-input>
      </el-form-item> -->

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import FormModule from '@/components/Form/FormModule'
import { Stockone } from '@/apis/stock/materialView'
import DetailItem from './DetailItem'
import { updateDictionary } from '@/apis/authManage/dataDictionary'
import { mapActions } from 'vuex'

export default {
  components: {
    FormModule,
    DetailItem
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      loading: false,
      inOutVos: [],
      submitForm: {
        breed: '',
        createTime: '',
        inOutVos: [
          {
            endTime: '',
            machineID: '',
            measureWeight: 0,
            minus: 0,
            name: '',
            netWeight: 0,
            startTime: '',
            status: 0,
            statusName: '',
            stockCode: ''
          }
        ],
        machineID: '',
        norms: '',
        orderID: '',
        remark: '',
        serialID: '',
        siteName: '',
        stockCode: '',
        stockNum: 0,
        userID: ''
      },
      rules: {
        dictCode: { required: true, message: '请输入字典编码' }
      }
    }
  },
  methods: {
    ...mapActions('account', ['refreshToken']),

    submit () {
      const submitForm = { ...this.submitForm }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          updateDictionary(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerOpen () {
      if (this.editData) {
        Stockone({
          id: this.editData.serialID
        }).then(res => {
          if (res.code == 200) {
            XEUtils.assign(this.editData, res.data)
            this.inOutVos = this.editData.inOutVos
            Object.keys(this.submitForm).forEach((key) => {
              let value = this.editData[key]
              if (key == 'stockNum' && value != '0') {
                value = Math.round(value / 1000)
              }
              if (value) this.submitForm[key] = value
            })
          }
        })
      }
    },

    handlerClose () {
      this.$emit('update:editData', this.editData)
      // const sourceForm = this.$options.data(this).submitForm
      // this.submitForm = Object.assign({}, this.submitForm, sourceForm)
    //  this.$refs.form.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .el-dialog__body {
  max-height: calc(95vh - 100px);
  overflow-y: auto;
}
::v-deep .el-dialog {
  top: 50%;
  margin: 0 auto 0!important;
  transform: translateY(-50%);
}
</style>
