import http from '@/utils/http'

// 材料看板列表
export function getMaterialViewPages (p) {
  return http.post('/kanban/stock/page', p)
}

// 操作阀门开关
export function operateSwitch (p) {
  return http.post('/kanban/stock/operate', p)
}

// 库存详情
export function Stockone (p) {
  return http.post('/kanban/stock/one', p)
}
