<template>
  <div class="relative">
    <!--    <div v-if="inOutOne.status== 0" class="absolute" style="position: absolute;  width: 42px; line-height: 20px; font-size:12px; top:0;left:0;color: white; background: green; border-radius: 10px 0  10px 0; text-align: center;">正常</div>
    <div class="absolute" v-else-if="inOutOne.status== 0" style="position: absolute;  width: 42px; line-height: 20px; font-size:12px; top:0;left:0;color: white; background: red; border-radius: 10px 0  10px 0; text-align: center;">拒收</div>
    <div class="absolute" v-else-if="inOutOne.status== 0" style="position: absolute;  width: 42px; line-height: 20px; font-size:12px; top:0;left:0;color: white; background: #A8A8A8; border-radius: 10px 0  10px 0; text-align: center;">退单</div>-->
    <div class="flex pl-20 pr-20 flex-column justify-around align-center" style=" box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);border-radius: 10px; width: 180px; ">
      <div class="flex flex-column justify-around align-center mt-5" style="height:50px;">
        <div class="fs-16 mt-5 mb-5">{{inOutOne.stockCode}}</div>
        <div class="mt-5 mb-5">{{inOutOne.startTime}}-{{inOutOne.endTime}}</div>
      </div>
      <div class="flex flex-column justify-between align-center mb-5">
        <el-input class="mt-5 mb-5" :value="inOutOne.netWeight" size="small" v-if="!type"></el-input>
        <el-input class="mt-5 mb-5" :value="inOutOne.measureWeight" size="small"></el-input>
        <el-input class="mt-5 mb-5" :value="inOutOne.minus" size="small"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inOutOne: {
      type: Object
    },
    type: {
      type: Number
    }
  }
}
</script>

<style scoped lang="scss">
.material-item {
  width: 167px;
  border-radius: 10px;
  background: #184aa5;
  .material-item-header {
    height: 67px;
  }
  .header-left-info {
    width: 60px;
    height: 100%;
  }
  .material-icon {
    opacity: .9;
  }
  .material-info-box {
    overflow: hidden;
  }
  // 垂直分割线
  .hr {
    height: 100%;
    width: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .action-group {
    color: #46FDFF;
    opacity: .9;
    height: 42px;
  }
  .cell-item {
    line-height: 36px;
  }
  .border-bottom {
    border-bottom: 1px solid rgba(255,255,255,.8);
  }
  .white {
    color: rgba(255,255,255,.8);
  }
  .lh-26 {
    line-height: 26px;
  }
}
</style>
